export const getDocuments = async (
  api,
  document_type_id,
  limit = 50,
  offset = 0
) => {
  const resp = await api.get('/document', {
    params: { document_type_id, limit, offset },
  });
  return resp.data;
};

export const getDocument = async (api, document_id) => {
  const resp = await api.get(`/document/${document_id}`);
  return resp.data;
};

export const createDocument = async (api, values) => {
  const resp = await api.post(`/document/`, values);
  return resp.data;
};

export const updateDocument = async (api, document_id, values) => {
  const resp = await api.put(`/document/${document_id}`, values);
  return resp.data;
};

export const deleteDocument = async (api, document_id) => {
  const resp = await api.delete(`/document/${document_id}`);
  return resp.data;
};

// export const convertToMarkdown = async (
//   api,
//   title,
//   type,
//   base64_contents,
//   pre_processing,
//   post_processing
// ) => {
//   const resp = await api.post(`/document/convert`, {
//     title,
//     type,
//     base64_contents,
//     pre_processing,
//     post_processing,
//   });
//   return resp.data;
// };
