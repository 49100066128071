import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Accordion,
  Typography,
  AccordionSummary,
  Stack,
  AccordionDetails,
} from '@mui/material';

import LoadingMask from '@/components/shared/LoadingMask';
import { useConfiguration } from '@/providers/ConfigurationContext';
import INSPECTION_TYPES from '@/components/inspectionTypes';
import { getInspectionChecks } from '@/actions/inspectionCheck';
import RunningInspectionDocumentChecks from './RunningInspectionDocumentChecks';

const POLL_INTERVAL = 5000; // 5 sec

async function getInspectionDocumentsWithChecks(api, inspection) {
  const inspection_checks = await getInspectionChecks(
    api,
    inspection.entity_id
  );

  if (inspection.type === INSPECTION_TYPES.EFFECTIVE) {
    const inspection_documents = await Promise.all(
      inspection.documents.map(async (document) => {
        return {
          ...document,
          checks: inspection_checks
            .filter((check) => check.document_id === document.document_id)
            .sort((a, b) => {
              if (a.check_section === b.check_section) {
                return (
                  (a.check_name > b.check_name) - (a.check_name < b.check_name)
                );
              } else {
                if (a.check_section === 'global') {
                  return -1;
                } else if (b.check_section === 'global') {
                  return 1;
                } else {
                  return (
                    (a.check_section > b.check_section) -
                    (a.check_section < b.check_section)
                  );
                }
              }
            }),
        };
      })
    );
    return inspection_documents;
  } else {
    return [
      {
        checks: inspection_checks.sort((a, b) => {
          if (a.check_section === b.check_section) {
            return (
              (a.check_name > b.check_name) - (a.check_name < b.check_name)
            );
          } else {
            if (a.check_section === 'global') {
              return -1;
            } else if (b.check_section === 'global') {
              return 1;
            } else {
              return (
                (a.check_section > b.check_section) -
                (a.check_section < b.check_section)
              );
            }
          }
        }),
      },
    ];
  }
}

function RunningInspection({ inspection }) {
  const configuration = useConfiguration();
  const [inspection_documents, setInspectionDocuments] = useState(null);

  const fetchInspectionChecks = useCallback(() => {
    getInspectionDocumentsWithChecks(configuration.api, inspection)
      .then((data) => {
        setInspectionDocuments(data);
      })
      .catch((error) => console.log(error));
  }, [inspection, configuration.api, setInspectionDocuments]);
  useEffect(() => {
    let interval;
    console.log('running effect');

    interval = setInterval(fetchInspectionChecks, POLL_INTERVAL);

    return () => {
      if (interval) {
        console.log('clearing interval');
        clearInterval(interval);
      }
    };
  }, [fetchInspectionChecks]);

  if (!inspection_documents) {
    return <LoadingMask />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      {inspection_documents.map((document, i) => {
        return (
          <Accordion key={document.document_id || 'draft_document'} expanded>
            {document.document_name ? (
              <AccordionSummary>
                <Stack direction='row' spacing={2} alignItems={'center'}>
                  <Typography variant='h6' gutterBottom>
                    {document.document_name}
                  </Typography>
                  {/* <Typography variant='body1'  gutterBottom>
                    {i === running_document
                      ? 'Running'
                      : i > running_document
                      ? 'Pending'
                      : 'Complete'}
                  </Typography> */}
                </Stack>
              </AccordionSummary>
            ) : undefined}
            <AccordionDetails>
              <RunningInspectionDocumentChecks
                inspection={inspection}
                checks={document.checks}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

export default RunningInspection;
