import React, { useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
  useTheme,
  Tab,
  Tabs,
  // Alert,
  LinearProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import InspectionDocument from './InspectionDocument';
import INSPECTION_STATUSES from '../../../inspectionStatuses';
import RunningInspection from './RunningInspection';
import getScoreCategoryDetails from '../getScoreCategoryDetails';
import getDocumentScore from '../getDocumentScore';
import INSPECTION_TYPES from '../../../inspectionTypes';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{
        display: 'flex',

        flexGrow: 1, // This ensures the TabPanel grows to fill available space
      }}
      width='100%'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, flexGrow: 1 }}>{children}</Box>}
    </div>
  );
}

function InspectionDocuments({ inspection, manifests, setInspection }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tab, setTab] = useState(0);
  const [selected_doc, setSelectedDoc] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const handleRowClick = (row) => {
    setSelectedDoc(row);
  };

  if (!inspection) {
    return <></>;
  }

  if (inspection?.status === INSPECTION_STATUSES.DRAFT) {
    if (inspection?.type === INSPECTION_TYPES.EFFECTIVE) {
      return (
        <Box padding='15px'>
          <Typography variant='subtitle'>Documents</Typography>
          <Divider />
          <br />
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: 'background.paper',
              display: 'flex',
            }}
          >
            <Tabs
              orientation='vertical'
              variant='scrollable'
              value={tab}
              onChange={handleTabChange}
              sx={{
                borderRight: 1,
                borderColor: 'divider',
                maxWidth: '200px',
                minWidth: '150px',
              }}
            >
              {(inspection?.documents || []).map((document, i) => {
                return (
                  <Tab
                    key={document.document_doc_id}
                    label={document.document_name}
                    value={i}
                    // sx={{ maxWidth: '200px', minWidth: '100px' }}
                  />
                );
              })}
            </Tabs>
            {(inspection?.documents || []).map((document, i) => {
              return (
                <TabPanel key={`${i}`} value={tab} index={i}>
                  <InspectionDocument
                    sx={{ width: '100%' }}
                    manifests={manifests}
                    inspection={inspection}
                    document={document}
                  />
                </TabPanel>
              );
            })}
          </Box>
        </Box>
      );
    } else {
      return (
        <Box padding='15px'>
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: 'background.paper',
              display: 'flex',
            }}
          >
            <InspectionDocument
              sx={{ width: '100%' }}
              manifests={manifests}
              inspection={inspection}
            />
          </Box>
        </Box>
      );
    }
  }

  if (
    [INSPECTION_STATUSES.RUNNING, INSPECTION_STATUSES.PENDING].includes(
      inspection?.status
    )
  ) {
    return (
      <>
        {/* <Alert severity='error'>
          Do not close or navigate from this page until inspection is complete.
        </Alert> */}
        <Box padding='15px'>
          <Typography variant='subtitle'>Running Inspection</Typography>
          <LinearProgress />
          <br />
          <RunningInspection
            inspection={inspection}
            setInspection={setInspection}
          />
        </Box>
      </>
    );
  }
  if (inspection?.type === INSPECTION_TYPES.EFFECTIVE) {
    return (
      <>
        <Box padding='15px'>
          <Typography variant='subtitle'>Inspection Results</Typography>
          <Divider />
          <br />
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: isMobile ? 400 : 650 }}
              stickyHeader
              size='small'
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Document</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      textAlign: 'left',
                    }}
                  >
                    Rating
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    Critical Checks
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    Non-Critical Checks
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inspection.documents.map((document) => (
                  <TableRow
                    key={document.document_entity_id}
                    onClick={() => handleRowClick(document)}
                    selected={
                      selected_doc?.document_entity_id ===
                      document.document_entity_id
                    } // Highlight selected row
                    sx={{
                      cursor: 'pointer',
                      backgroundColor:
                        selected_doc?.document_entity_id ===
                        document.document_entity_id
                          ? 'rgba(0, 0, 255, 0.1)'
                          : 'inherit',
                    }}
                  >
                    <TableCell>
                      <Typography variant='body'>
                        {document.document_name}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant='body'>
                        {
                          getScoreCategoryDetails(
                            getDocumentScore(
                              document?.critical_compliant +
                                document?.critical_non_compliant,
                              document?.non_critical_compliant +
                                document?.non_critical_non_compliant,
                              document?.critical_compliant,
                              document?.non_critical_compliant
                            )
                          )?.label
                        }
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'right',
                      }}
                    >
                      <Typography
                        variant='body'
                        sx={{ textDecoration: 'underline' }}
                      >
                        {document?.critical_compliant} /{' '}
                        {document?.critical_compliant +
                          document?.critical_non_compliant}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'right',
                      }}
                    >
                      <Typography
                        variant='body'
                        sx={{ textDecoration: 'underline' }}
                      >
                        {document?.non_critical_compliant} /{' '}
                        {document?.non_critical_compliant +
                          document?.non_critical_non_compliant}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          {selected_doc ? (
            <InspectionDocument
              key={selected_doc.document_entity_id}
              inspection={inspection}
              document={selected_doc}
              manifests={manifests}
            />
          ) : undefined}
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box padding='15px'>
          <InspectionDocument
            key={inspection.entity_id}
            inspection={inspection}
            manifests={manifests}
          />
        </Box>
      </>
    );
  }
}

export default InspectionDocuments;
