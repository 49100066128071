import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Stack,
  Box,
  Typography,
  Toolbar,
  Divider,
  Button,
  Link,
} from '@mui/material';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useUser } from '@/providers/UserContext';
import LoadingMask from '@/components/shared/LoadingMask';

import { useConfiguration } from '@/providers/ConfigurationContext';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { deleteInspection, getInspections } from '@/actions/inspection';
import INSPECTION_TYPES from '@/components/inspectionTypes';
import InspectionDeleteDialog from '../InspectionDeleteDialog';

function Inspections({ type }) {
  const user = useUser();
  const configuration = useConfiguration();
  const navigate = useNavigate();
  const [inspections, setInspections] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const base_path =
    type === INSPECTION_TYPES.EFFECTIVE
      ? '/quartermaster/internalAudit'
      : '/quartermaster/evaluator';

  useEffect(() => {
    if (user?.entity_id && !inspections) {
      getInspections(configuration.api, type)
        .then((data) => {
          data.sort(
            (a, b) =>
              new Date(b.updated_at).valueOf() -
              new Date(a.updated_at).valueOf()
          );
          setInspections(data);
        })
        .catch((error) => console.log(error));
    }
  }, [user?.entity_id, type, inspections, setInspections, configuration.api]);

  const handleDeleteInspection = async (inspection_id) => {
    await deleteInspection(configuration.api, inspection_id);
    const new_inspections = [...inspections].filter(
      (inspection) => inspection.entity_id !== inspection_id
    );

    setInspections(new_inspections);
  };

  const handleRowSelection = useCallback(
    (value) => {
      setRowSelectionModel(value);
    },
    [setRowSelectionModel]
  );

  const renderName = useCallback(
    (params) => {
      return (
        <Link component={RouterLink} to={`${base_path}/${params.id}`}>
          {params.row.name}
        </Link>
      );
    },
    [base_path]
  );

  const renderStatus = useCallback(({ row }) => {
    return <Typography>{row.status}</Typography>;
  }, []);

  const convertDate = useCallback(({ value }) => {
    if (!value) {
      return value;
    }
    return new Date(value.replace(' ', 'T') + 'Z');
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        type: 'string',
        renderCell: renderName,
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        flex: 0.5,
        renderCell: renderStatus,
      },
      {
        field: 'created_at',
        headerName: 'Started',
        type: 'dateTime',
        flex: 0.75,
        valueGetter: convertDate,
        // renderCell: renderName,
      },
      {
        field: 'updated_at',
        headerName: 'Last Updated',
        type: 'dateTime',
        flex: 0.75,
        valueGetter: convertDate,
        // renderCell: renderName,
      },
    ],
    [renderName, convertDate, renderStatus]
  );

  if (!inspections) {
    return <LoadingMask />;
  }

  return (
    <>
      <Toolbar
        sx={{
          minHeight: '55px !important',
        }}
      >
        <Typography variant='h6' sx={{ mr: 4 }}>
          {type === INSPECTION_TYPES.EFFECTIVE
            ? 'Internal Audits'
            : 'Document Evaluations'}
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <Button
            onClick={() => {
              navigate(`${base_path}/edit`);
            }}
            variant='contained'
            startIcon={<AddCircleOutlineOutlinedIcon />}
          >
            New{' '}
            {type === INSPECTION_TYPES.EFFECTIVE ? 'Inspection' : 'Evaluation'}
          </Button>
          <Button
            onClick={() => {
              navigate({
                pathname: '/quartermaster/externalAudit/edit',
                search: `?audit_id=${rowSelectionModel[0]}`,
              });
            }}
            disabled={rowSelectionModel.length !== 1}
            startIcon={<EditOutlinedIcon />}
          >
            Edit
          </Button>
          <Button
            onClick={() => setDeleteOpen(true)}
            disabled={rowSelectionModel.length !== 1}
            startIcon={<DeleteOutlinedIcon />}
          >
            Delete
          </Button>
        </Stack>
      </Toolbar>
      <Divider />
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <DataGrid
              checkboxSelection
              columnHeaderHeight={35}
              getRowId={(row) => row.entity_id}
              columns={columns}
              rows={inspections || []}
              onSelectionModelChange={handleRowSelection}
              onRowSelectionModelChange={handleRowSelection}
              rowSelectionModel={rowSelectionModel}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  sx: {
                    button: {
                      color: 'black',
                    },
                    svg: {
                      color: 'black',
                    },
                  },
                  showQuickFilter: true,
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <InspectionDeleteDialog
        key={`delete_${rowSelectionModel[0]?.updated_at}`}
        inspection={{ entity_id: rowSelectionModel[0] }}
        open={deleteOpen}
        deleteInspection={handleDeleteInspection}
        handleClose={() => setDeleteOpen(false)}
      />
    </>
  );
}

export default Inspections;
